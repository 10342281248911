import { Badge, Card, Col, Form, Row, Stack, Table } from "react-bootstrap";

import { useState } from "react";
import { BUILDING_PROFILES } from "../../sharedEnums";
import { genEnergyDemandChartData } from "./chartDataFormatting";
import { USAGE_PROFILE_LABELS } from "./enums";
import { limitDecimalPlaces, numString2dp } from "./utils";

import { BorderedCheckbox } from "@inrange/theme-components";
import { YearDayEnergyChart } from "@inrange/theme-components/charts";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Demand = ({
  site,
  setSite,
  siteCalculations,
  errors,
  onBuildingProfileChange,
}) => {
  const haveActualDemandData = site.halfHourlyDemand?.source === "hh_data";

  const demandCoefficient = haveActualDemandData
    ? siteCalculations.totalBuildingArea > 0
      ? (siteCalculations.energyFlowAnnual?.demand || 0) /
        siteCalculations.totalBuildingArea
      : 0
    : site.demandCoefficientKWhPerM2;

  return (
    <DemandView
      site={site}
      setSite={setSite}
      errors={errors}
      installedCapacity={siteCalculations.installedCapacity}
      totalBuildingArea={siteCalculations.totalBuildingArea}
      demandCoefficient={demandCoefficient}
      annualDemand={siteCalculations.energyFlowAnnual?.demand || 0}
      annualBehindMeter={siteCalculations.energyFlowAnnual?.behindMeter || 0}
      monthlyTenantEnergyShare={siteCalculations.monthlyTenantEnergyShare}
      tenantShare={siteCalculations.energyFlowAnnual?.tenantEnergyShare}
      haveActualDemandData={haveActualDemandData}
      onBuildingProfileChange={onBuildingProfileChange}
    />
  );
};

const DemandView = ({
  site,
  totalBuildingArea,
  errors,
  setSite,
  demandCoefficient,
  onBuildingProfileChange,
  annualDemand,
  annualBehindMeter,
  monthlyTenantEnergyShare,
  tenantShare,
  haveActualDemandData,
}) => {
  const [displayMonthlyTenantEnergyShare, setDisplayMonthlyTenantEnergyShare] =
    useState(false);
  const [displayDemandGraph, setDisplayDemandGraph] = useState(false);
  const [energyProcuredChartPeriod, setEnergyProcuredChartPeriod] =
    useState("summer");

  const updateTimeOfUse = (profileShape) => {
    setSite({ profileShape });
  };

  const demandSource = haveActualDemandData ? (
    <Badge bg="success">Actual</Badge>
  ) : (
    <Badge bg="warning">Inferred</Badge>
  );

  const demandChartData = genEnergyDemandChartData(
    site,
    energyProcuredChartPeriod
  );

  const renderDemandMetadata = () => {
    const metadata = site?.halfHourlyDemand?.metadata;
    const haveDemandMetadata =
      haveActualDemandData &&
      !!metadata?.latest_full_year_num_rows_customer_data &&
      !!metadata?.latest_full_year_num_rows;
    if (!haveDemandMetadata) return null;

    const percentageRealData = (
      (metadata.latest_full_year_num_rows_customer_data /
        metadata.latest_full_year_num_rows) *
      100
    ).toFixed(2);
    return (
      <>
        <p>
          Metadata: {percentageRealData}% of data directly from input HH data
        </p>
      </>
    );
  };

  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Demand</strong>
      </Form.Label>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="buildingProfile">
            <Form.Label>Building profile</Form.Label>
            <Form.Select
              value={site.buildingProfile}
              onChange={(e) => {
                const newProfile = e.target.value;
                onBuildingProfileChange(newProfile);
              }}
              data-testid={"buildingProfile"}
            >
              {Object.entries(BUILDING_PROFILES).map(
                ([value, profileTitle], index) => (
                  <option value={value} key={index}>
                    {profileTitle}
                  </option>
                )
              )}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="demandCoefficient">
            <Form.Label>Coefficient (kWh/m²)</Form.Label>
            <Form.Control
              type="text"
              value={limitDecimalPlaces(demandCoefficient ?? "", 2)}
              onChange={(e) => {
                const value = limitDecimalPlaces(e.target.value, 2);
                setSite({
                  demandCoefficientKWhPerM2: value,
                });
              }}
              isInvalid={errors.demandCoefficientKWhPerM2}
              disabled={
                haveActualDemandData || site.buildingProfile === "noDemand"
              }
              data-testid={"demandCoefficient"}
            />
            <Form.Control.Feedback type="invalid">
              {errors.demandCoefficientKWhPerM2}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="totalBuildingArea">
            <Form.Label>Total building area (m²)</Form.Label>
            <Form.Control
              disabled
              value={numString2dp(totalBuildingArea) ?? ""}
              data-testid={"totalBuildingArea"}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="annualDemand">
            <Form.Label>Annual demand (kWh)</Form.Label>
            <Form.Control
              type="text"
              value={numString2dp(annualDemand ?? 0)}
              onChange={(e) =>
                setSite({
                  tenantAnnualDemandKwh: e.target.value,
                })
              }
              isInvalid={errors.tenantAnnualDemandKwh}
              disabled
              data-testid={"annualDemand"}
            />
            <Form.Control.Feedback type="invalid">
              {errors.tenantAnnualDemandKwh}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="tenantEnergyShare">
            <Form.Label>Tenant energy share</Form.Label>
            <Form.Control
              type="text"
              value={tenantShare ? tenantShare.toFixed(4) : ""}
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="consumedOnsite">
            <Form.Label>Consumed on-site (kWh)</Form.Label>
            <Form.Control
              type="text"
              value={numString2dp(annualBehindMeter)}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xl="8" xxl="5">
          <Form.Group>
            <Stack direction="horizontal">
              <Form.Label>
                <strong>Demand profile</strong>
              </Form.Label>
              <div className="ms-auto">{demandSource}</div>
            </Stack>
            <div>
              <div style={{ fontSize: 12, marginBottom: 16 }}>
                When is most of the energy consumed?
              </div>
              <Form
                style={{
                  display: "flex",
                  fontSize: 11,
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                {Object.entries(USAGE_PROFILE_LABELS).map(([key, label]) => (
                  <BorderedCheckbox
                    key={key}
                    formName="demand_consumption_time"
                    value={key}
                    label={label}
                    selectedOption={
                      haveActualDemandData ||
                      site.buildingProfile === "noDemand"
                        ? "none"
                        : site.profileShape
                    }
                    setSelectedOption={updateTimeOfUse}
                    disabled={
                      site.buildingProfile === "noDemand" ||
                      haveActualDemandData
                    }
                  />
                ))}
              </Form>
            </div>
          </Form.Group>
        </Col>
        <Col>
          {displayMonthlyTenantEnergyShare ? (
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Tenant share (%)</th>
                </tr>
              </thead>
              <tbody>
                {monthlyTenantEnergyShare.map((energyShare, index) => (
                  <tr key={index}>
                    <td>{months[index]}</td>
                    <td>{energyShare.toFixed(4)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div
              style={{
                textAlign: "left",
                color: "rgb(13, 110, 253)",
                paddingTop: "30px",
                paddingLeft: "20%",
              }}
            >
              <a
                onClick={() => setDisplayMonthlyTenantEnergyShare(true)}
                style={{
                  cursor: "pointer",
                }}
              >
                Show monthly tenant shares
              </a>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col xl="8" xxl="5">
          {haveActualDemandData && !displayDemandGraph && (
            <div
              style={{
                textAlign: "center",
                color: "rgb(13, 110, 253)",
              }}
            >
              <a onClick={() => setDisplayDemandGraph(true)}>
                Show demand graph
              </a>
            </div>
          )}
        </Col>
      </Row>
      {haveActualDemandData && displayDemandGraph && (
        <Row>
          <Col>
            <YearDayEnergyChart
              chartData={demandChartData}
              indexBy={energyProcuredChartPeriod === "year" ? "month" : "time"}
              legendKeys={{ Demand: "Demand" }}
              energyProcuredChartPeriod={energyProcuredChartPeriod}
              setEnergyProcuredChartPeriod={setEnergyProcuredChartPeriod}
              height={"350px"}
              $padding={"0px"}
            />
            {renderDemandMetadata()}
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default Demand;
