export const genEnergyDemandChartData = (site, period) => {
  if (period === "year") return genEnergyDemandChartDataYear(site);
  return genEnergyDemandChartDataDay(site, period);
};

export const genEnergyDemandChartDataDay = (site, period) => {
  const chartData = [];

  let hour = 0;
  let minute = 0;

  const energyFlowDay =
    period === "summer" ? site.energyFlowSummerDay : site.energyFlowWinterDay;

  if (!energyFlowDay) return chartData;

  for (const _ of energyFlowDay.demand) {
    const hourText = hour.toString().padStart(2, "0");
    const minuteText = minute.toString().padStart(2, "0");

    chartData.push({
      time: `${hourText}:${minuteText}`,
      Demand: 0,
    });

    if (minute === 30) {
      minute = 0;
      hour += 1;
    } else {
      minute += 30;
    }
  }

  for (let ix = 0; ix < energyFlowDay.demand.length; ix++) {
    chartData[ix]["Demand"] += energyFlowDay.demand[ix];
  }

  return chartData;
};

export const genEnergyDemandChartDataYear = (site) => {
  const oneMonthChartData = (monthIx) => {
    const siteData = site.energyFlowMonthly[monthIx];
    const chartData = {
      Demand: siteData.demand,
    };
    return chartData;
  };

  return [
    {
      month: "Jan",
      ...oneMonthChartData("1"),
    },
    {
      month: "Feb",
      ...oneMonthChartData("2"),
    },
    {
      month: "Mar",
      ...oneMonthChartData("3"),
    },
    {
      month: "Apr",
      ...oneMonthChartData("4"),
    },
    {
      month: "May",
      ...oneMonthChartData("5"),
    },
    {
      month: "Jun",
      ...oneMonthChartData("6"),
    },
    {
      month: "Jul",
      ...oneMonthChartData("7"),
    },
    {
      month: "Aug",
      ...oneMonthChartData("8"),
    },
    {
      month: "Sep",
      ...oneMonthChartData("9"),
    },
    {
      month: "Oct",
      ...oneMonthChartData("10"),
    },
    {
      month: "Nov",
      ...oneMonthChartData("11"),
    },
    {
      month: "Dec",
      ...oneMonthChartData("12"),
    },
  ];
};
