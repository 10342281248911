import { useOrganisation } from "@inrange/building-manager-api-client";
import { formatDateTimeFromSeconds } from "@inrange/theme-components";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import { csvHeaders, formatCsvRow } from "../../utils/SitesCsvUtil";
import getCustomerAppDomain from "../../utils/getCustomerAppDomain";
import Loading from "../Loading";
import { BUILDING_PROFILES } from "../sharedEnums";
import MapOfSites from "./MapOfSites";

const OrgEdit = () => {
  const orgTypeMap = {
    Landlord: { isTest: false, orgType: "Landlord" },
    Tenant: { isTest: false, orgType: "Tenant" },
    "Test Landlord": { isTest: true, orgType: "Landlord" },
    "Test Tenant": { isTest: true, orgType: "Tenant" },
  };
  const { orgId } = useParams();
  const {
    fetchOrganisation,
    updateOrganisation,
    addOrgUser,
    removeOrgUser,
    useOrganisationSites,
  } = useOrganisation(orgId);
  const [usersIsRemoving, setUsersIsRemoving] = useState({});

  const organisation = fetchOrganisation.data?.organisation;
  const sites = useOrganisationSites(organisation?.siteOwnerships);

  const onSave = (orgType, buildingProfile) => {
    const update = {
      organisation: {
        ...orgTypeMap[orgType],
        buildingProfile,
      },
    };
    updateOrganisation.mutate(update);
  };

  if (updateOrganisation.isError) {
    alert(`Error updating org - ${updateOrganisation.error.message}`);
    updateOrganisation.reset();
  }

  if (updateOrganisation.isSuccess) {
    updateOrganisation.reset();
  }

  const onAddUser = (email) => {
    addOrgUser.mutate(
      { email },
      {
        onError: (error) => {
          alert(error?.response?.data?.message || error);
        },
        onSuccess: () => {
          setUsersIsRemoving({ ...usersIsRemoving, [email]: false });
        },
      }
    );
  };

  const onRemoveUser = (email) => {
    setUsersIsRemoving({ ...usersIsRemoving, [email]: true });
    removeOrgUser.mutate(
      { email },
      {
        onError: (error) => {
          setUsersIsRemoving({ ...usersIsRemoving, [email]: false });
          alert(error?.response?.data?.message || error);
        },
      }
    );
  };

  // Check if the site data is loading
  const anyOrgSitesLoading = sites
    .map((orgSite) => orgSite.isLoading)
    .some((bool) => bool);

  if (fetchOrganisation.isLoading)
    return <Loading label="Loading organisation ..." />;

  if (anyOrgSitesLoading) {
    return <Loading label={`Loading site data for ${organisation.name}...`} />;
  }

  const successfulSites = sites
    .filter((site) => site.isSuccess)
    .map((site) => site.data?.site)
    .sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

  document.title = `Organisation: ${organisation.name}`;
  return (
    <OrgEditView
      organisation={organisation}
      onSave={onSave}
      onAddUser={onAddUser}
      onRemoveUser={onRemoveUser}
      orgTypes={Object.keys(orgTypeMap)}
      sites={successfulSites}
      orgIsUpdating={updateOrganisation.isLoading}
      usersIsRemoving={usersIsRemoving}
    />
  );
};

const OrgEditView = ({
  organisation,
  onSave,
  onAddUser,
  onRemoveUser,
  sites,
  orgTypes,
  orgIsUpdating,
  usersIsRemoving,
}) => {
  return (
    <Container>
      <OrgHeader organisation={organisation} />
      <OrgTypeEdit
        organisation={organisation}
        orgTypes={orgTypes}
        onSave={onSave}
        orgIsUpdating={orgIsUpdating}
      />
      <SitesTable sites={sites} organisation={organisation} />
      <OrgUsers
        organisation={organisation}
        onAddUser={onAddUser}
        onRemoveUser={onRemoveUser}
        usersIsRemoving={usersIsRemoving}
      />
      <MapOfSites sites={sites} />
    </Container>
  );
};

const OrgHeader = ({ organisation }) => {
  const updateAtStr = formatDateTimeFromSeconds(organisation.updatedAt);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <div>
        <p className="text-muted mt-4">Updated: {updateAtStr}</p>
        <h1 className="mt-0">{organisation?.name}</h1>
      </div>
      <a
        href={`${getCustomerAppDomain()}/org/${organisation?.id}/dashboard`}
        target="_blank"
        rel="noreferrer"
        className="mt-4"
      >
        <Button size="lg" variant="success">
          Public portolio
        </Button>
      </a>
    </div>
  );
};

const OrgTypeEdit = ({ organisation, orgTypes, onSave, orgIsUpdating }) => {
  const initialOrgType =
    (organisation.isTest ? "Test " : "") + organisation.orgType;
  const [orgType, setOrgType] = useState(initialOrgType);
  const [buildingProfile, setBuildingProfile] = useState(
    organisation.buildingProfile
  );

  return (
    <Row>
      <Col>
        <Form.Group className="mb-3">
          <Form.Label>Organisation type</Form.Label>
          <Form.Select
            value={orgType}
            onChange={(e) => setOrgType(e.target.value)}
          >
            <option hidden disabled value="">
              Select Type
            </option>
            {orgTypes.map((key) => (
              <option key={key} value={key}>
                {key}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      {(orgType === "Tenant" || orgType === "Test Tenant") && (
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Default building profile</Form.Label>
            <Form.Select
              value={buildingProfile ?? ""}
              onChange={(e) =>
                setBuildingProfile(
                  e.target.value === "" ? null : e.target.value
                )
              }
            >
              <option disabled value="">
                Select profile
              </option>
              {Object.entries(BUILDING_PROFILES).map(
                ([value, profileTitle], index) => (
                  <option value={value} key={index}>
                    {profileTitle}
                  </option>
                )
              )}
            </Form.Select>
          </Form.Group>
        </Col>
      )}
      <Col>
        <Button
          variant="primary"
          onClick={() => onSave(orgType, buildingProfile)}
          style={{ marginTop: 32 }}
          disabled={orgIsUpdating}
        >
          Save
        </Button>
      </Col>
    </Row>
  );
};

const SitesTable = ({ organisation, sites }) => {
  const csvData = sites.map((site) => {
    return formatCsvRow(site, organisation.id);
  });
  const dateString = new Date().toISOString().split(".")[0];
  const csvFileName = organisation.name + " Site List " + dateString + ".csv";

  return (
    <div style={{ margin: "50px 0" }}>
      <Row style={{ marginBottom: 32 }}>
        <Col
          style={{
            justifyContent: "flex-start",
            alignContent: "start",
            display: "flex",
            gap: 16,
          }}
        >
          <h4>
            Sites <span>({sites?.length})</span>
          </h4>
          <div>
            <CSVLink data={csvData} headers={csvHeaders} filename={csvFileName}>
              <Button variant="primary">Download sites data</Button>
            </CSVLink>
          </div>
        </Col>
        <Col
          style={{
            justifyContent: "flex-end",
            alignContent: "end",
            display: "flex",
            gap: 32,
          }}
        >
          <div>
            <a
              href={`/site/new?org=${organisation?.id}${
                organisation?.orgType === "Tenant" &&
                organisation?.buildingProfile
                  ? "&profile=" + organisation?.buildingProfile
                  : ""
              }`}
            >
              <Button variant="primary">New site</Button>
            </a>
          </div>
        </Col>
      </Row>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Building name</th>
            <th>Property record ID</th>
            <th>Status</th>
            <th>Address</th>
            <th>Last modified date</th>
          </tr>
        </thead>
        <tbody>
          {sites?.map((site, ii) => (
            <tr key={ii}>
              <td>
                <a href={`/site/${site.id}/edit`}>{site.name}</a>
                <br />
                {site.isScenario ? "(Excluded from customer-facing SDM)" : ""}
              </td>
              <td width="20%" style={{ fontSize: 13 }}>
                {site.id}
              </td>
              <td width="15%" className="secondary" style={{ fontSize: 14 }}>
                {site.siteOwnerships.map((siteOrg) => (
                  <div key={siteOrg.orgID} style={{ fontSize: 13 }}>
                    {siteOrg.ownership}: <strong>{siteOrg.name}</strong>
                  </div>
                ))}
                <div style={{ fontSize: 13 }}>
                  Investment Model: <strong>{site.investmentModel}</strong>
                </div>
              </td>
              <td className="secondary" align="left">
                {site.address}
              </td>
              <td className="secondary" align="left">
                {formatDateTimeFromSeconds(site.updatedAt)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const OrgUsers = ({
  organisation,
  onAddUser,
  onRemoveUser,
  usersIsRemoving,
}) => {
  const [email, setEmail] = useState("");

  const addUser = (email) => {
    onAddUser(email);
    setEmail("");
  };

  return (
    <div>
      <h4>Manage users ({organisation.userEmails?.length || 0})</h4>
      {organisation.userEmails?.length > 0 && (
        <>
          <Table bordered style={{ fontSize: 14 }}>
            <thead>
              <tr>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {organisation?.userEmails?.map((userEmail) => (
                <tr key={userEmail}>
                  <td>{userEmail}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => onRemoveUser(userEmail)}
                      disabled={usersIsRemoving[userEmail]}
                    >
                      Remove user
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Email address"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          variant="primary"
          onClick={() => addUser(email)}
          style={{ marginRight: 10 }}
        >
          Add user
        </Button>
      </InputGroup>
    </div>
  );
};

export default OrgEdit;
