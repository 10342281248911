import { Button, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useSite } from "@inrange/building-manager-api-client";

const SiteDebug = () => {
  const { siteId } = useParams();
  const { fetchSiteEnergyFlow } = useSite({ siteId });

  return (
    <>
      <h4>Debug Actions for Site ID: {siteId}</h4>
      <Row>
        <Col sm={3} className="d-grid">
          <Button onClick={() => fetchSiteEnergyFlow.mutate(false)}>
            Download Site Energy Flow (HH)
          </Button>
        </Col>
        <Col sm={3} className="d-grid">
          <Button onClick={() => fetchSiteEnergyFlow.mutate(true)}>
            Download Site Energy Flow (Daily)
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SiteDebug;
