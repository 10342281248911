import { useBuildingsInRegionList } from "@inrange/building-manager-api-client";
import { useBuildingsByBounds } from "@inrange/inrange-data-api-client";
import { polygon } from "@turf/helpers";
import { useEffect, useState } from "react";
import BuildingMap from "./BuildingMap";
import { MapStyles } from "./MapStyles";
import {
  bmaBuildingToOsmBuilding,
  isIntersectingWithPolygon,
} from "./siteGeoUtils";

const SiteListGeo = ({
  sites,
  selectedOrgs,
  setSiteIdsInRegion,
  setSitesInViewLoading,
}) => {
  // Handle the map
  const [map, setMap] = useState();
  const [mapStyle, setMapStyle] = useState(MapStyles.streets);
  const [mapBounds, setMapBounds] = useState();
  const [siteMarkers, setSiteMarkers] = useState(sites);

  const params = new URL(document.location).searchParams;
  const testOrgId = params.get("testOrgId");

  useEffect(() => {
    if (!map) return;
    setMapBounds(map.getBounds());
  }, [map]);

  const zoomToSite = (site) => {
    if (!map) return;
    map.flyTo([site.latitude, site.longitude], 16, {
      animate: true,
      duration: 1,
    });
  };

  // Handle the buldings in the map - both site buildings and buildings in bounds
  const [networkBuildingsInBounds, setNetworkBuildingsInBounds] = useState({}); // These buildings owned by other sites
  const [availableBuildingsInBounds, setAvailableBuildingsInBounds] = useState(
    {}
  ); // These are selectable polygons
  const [buildingIntersections, setBuildingIntersections] = useState({}); // Object to store a boolean for each building in bounds to check if it intersects with the site

  const fetchAvailableBuildingsInBounds = useBuildingsByBounds(mapBounds);
  const fetchNetworkBuildingsInBounds = useBuildingsInRegionList(
    mapBounds,
    true,
    testOrgId
  );

  useEffect(() => {
    let validOrgs = selectedOrgs;

    if (testOrgId) {
      validOrgs = [...validOrgs, testOrgId];
    }

    if (selectedOrgs.length > 0) {
      setSiteMarkers(
        sites.filter((site) =>
          site.siteOwnerships.some((ownership) =>
            validOrgs.includes(ownership.orgID)
          )
        )
      );
    } else {
      setSiteMarkers(sites);
    }
  }, [testOrgId, selectedOrgs, sites]);

  useEffect(() => {
    if (fetchNetworkBuildingsInBounds.isLoading) {
      setSitesInViewLoading(true);
    }

    if (!fetchNetworkBuildingsInBounds.data) return;

    setSitesInViewLoading(false);

    const siteIdsInRegion = fetchNetworkBuildingsInBounds.data.sites;
    setSiteIdsInRegion(siteIdsInRegion);

    if (!fetchAvailableBuildingsInBounds.data) return;

    const networkBuildings = {};

    Object.keys(fetchNetworkBuildingsInBounds.data.buildings).forEach((key) => {
      const building = fetchNetworkBuildingsInBounds.data.buildings[key];
      networkBuildings[key] = {
        ...bmaBuildingToOsmBuilding(building),
        siteIDs: building.siteIDs, // so that we can populate the popup
      };
    });

    const availableBuildings = {};

    const networkBuildingValues = Object.values(networkBuildings);
    const networkBuildingPolygons = Object.values(networkBuildingValues).map(
      (building) => {
        const coordinates = building.geometry.coordinates[0];
        if (coordinates[0][0] !== coordinates[coordinates.length - 1][0]) {
          // If the polygon is not closed, close it
          coordinates.push(coordinates[0]);
        }
        return polygon([coordinates]);
      }
    );

    Object.keys(fetchAvailableBuildingsInBounds.data).forEach((key) => {
      if (buildingIntersections[key] === undefined) {
        buildingIntersections[key] = isIntersectingWithPolygon(
          fetchAvailableBuildingsInBounds.data[key],
          networkBuildingPolygons
        );
      }
      if (!buildingIntersections[key]) {
        availableBuildings[key] = fetchAvailableBuildingsInBounds.data[key];
      }
    });

    setBuildingIntersections(buildingIntersections);
    setAvailableBuildingsInBounds(availableBuildings);
    setNetworkBuildingsInBounds(networkBuildings);
  }, [
    setSitesInViewLoading,
    setSiteIdsInRegion,
    sites,
    buildingIntersections,
    fetchAvailableBuildingsInBounds.data,
    fetchNetworkBuildingsInBounds.data,
    fetchNetworkBuildingsInBounds.isLoading,
    mapBounds,
  ]);

  // Address Bar
  const addressCallback = (suggestion) => {
    if (suggestion.bbox) {
      map.fitBounds([
        [suggestion.bbox[1], suggestion.bbox[0]],
        [suggestion.bbox[3], suggestion.bbox[2]],
      ]);
    } else {
      const [lng, lat] = suggestion.center;
      map.setView([lat, lng], 16);
    }
  };

  return (
    <SiteListGeoView
      sites={sites}
      siteMarkers={siteMarkers}
      addressCallback={addressCallback}
      buildingsInBounds={availableBuildingsInBounds}
      networkBuildingsInBounds={networkBuildingsInBounds}
      map={map}
      mapStyle={mapStyle}
      setMap={setMap}
      setMapBounds={setMapBounds}
      setMapStyle={setMapStyle}
      zoomToSite={zoomToSite}
    />
  );
};

export default SiteListGeo;

const SiteListGeoView = ({
  sites,
  siteMarkers,
  addressCallback,
  buildingsInBounds,
  networkBuildingsInBounds,
  map,
  mapStyle,
  setMap,
  setMapBounds,
  setMapStyle,
  zoomToSite,
}) => {
  return (
    <BuildingMap
      sites={sites}
      siteMarkers={siteMarkers}
      map={map}
      mapStyle={mapStyle}
      setMapStyle={setMapStyle}
      setMap={setMap}
      isSiteList={true}
      buildingsInBounds={buildingsInBounds}
      networkBuildingsInBounds={networkBuildingsInBounds}
      addressCallback={addressCallback}
      setMapBounds={setMapBounds}
      mapHeight={"600px"}
      zoomToSite={zoomToSite}
    />
  );
};
