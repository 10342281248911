import { Modal, ModalView } from "@inrange/theme-components";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PVCustomObjectsMap from "./PVCustomObjectsMap";

const PVCustomObjectsModal = ({
  buildings,
  siteCenter,
  customObjects,
  setCustomObjects,
  setShowModal,
  triggerCustomObjectsRebuild,
}) => {
  const [newCustomObjects, setNewCustomObjects] = useState(
    customObjects.reduce((acc, customObject) => {
      acc[customObject.id] = customObject;
      return acc;
    }, {})
  );
  const customObjectsCount = Object.keys(newCustomObjects).length;

  const onUseCustomObjects = () => {
    const customObjects = Object.values(newCustomObjects);
    setCustomObjects(customObjects);
    triggerCustomObjectsRebuild(customObjects);
    setShowModal(false);
  };

  const updateNewCustomObjects = (id, geometry) => {
    setNewCustomObjects((prevNewCustomObjects) => {
      const newCustomObjects = { ...prevNewCustomObjects };
      newCustomObjects[id] = { ...geometry, id };
      return newCustomObjects;
    });
  };

  const deleteCustomObject = (id) => {
    setNewCustomObjects((prevNewCustomObjects) => {
      const newCustomObjects = { ...prevNewCustomObjects };
      delete newCustomObjects[id];
      return newCustomObjects;
    });
  };

  const onCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal>
      <ModalView width={"848px"}>
        <div>
          <PVCustomObjectsMap
            buildings={buildings}
            siteCenter={siteCenter}
            newCustomObjects={newCustomObjects}
            updateNewCustomObjects={updateNewCustomObjects}
            deleteCustomObject={deleteCustomObject}
          />
        </div>
        <Row>
          <Col>
            <Button
              variant="secondary"
              onClick={onCancel}
              style={{ width: "130px", height: "62px" }}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              variant="success"
              onClick={onUseCustomObjects}
              style={{ float: "right", width: "300px", height: "62px" }}
            >
              {`Use ${customObjectsCount} custom object ${customObjectsCount === 1 ? "boundary" : "boundaries"}, generate PV layout`}
            </Button>
          </Col>
        </Row>
      </ModalView>
    </Modal>
  );
};

export default PVCustomObjectsModal;
