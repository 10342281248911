import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { pounds } from "../siteSections/utils";

const FinancialReturns = ({ siteCalculations, isOwnerOccupied }) => {
  const ownershipKey = isOwnerOccupied ? "ownerOccupier" : "landlord";
  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Customer financial returns</strong>
      </Form.Label>
      <Row>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="financialModelIrr">
            <Form.Label>IRR (%)</Form.Label>
            <Form.Control
              disabled
              value={
                siteCalculations.financialModels[ownershipKey]?.license?.irr ||
                "N/A"
              }
              data-testid="financial-model-irr"
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="paybackMonths">
            <Form.Label>Payback (months)</Form.Label>
            <Form.Control
              disabled
              value={
                siteCalculations.financialModels[ownershipKey]?.license
                  ?.paybackMonths || "N/A"
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="licenseRevenueY1">
            <Form.Label>License revenue (Y1)</Form.Label>
            <Form.Control
              disabled
              value={pounds(
                siteCalculations.financialModels[ownershipKey]?.license
                  ?.revenue || 0
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="licenseRevenueLT">
            <Form.Label>License revenue (LT)</Form.Label>
            <Form.Control
              disabled
              value={pounds(
                siteCalculations.financialModels[ownershipKey]?.license
                  ?.lifetimeRevenue || 0
              )}
            />
          </Form.Group>
        </Col>
        {!isOwnerOccupied && (
          <Col>
            <Form.Group className="mb-3" controlId="tenantSavingsY1">
              <Form.Label>Tenant savings (Y1)</Form.Label>
              <Form.Control
                disabled
                value={pounds(
                  siteCalculations.financialModels.tenant?.savings || 0
                )}
              />
            </Form.Group>
          </Col>
        )}
        {isOwnerOccupied && (
          <Col>
            <Form.Group className="mb-3" controlId="ooSavingsY1">
              <Form.Label>License OO savings (Y1)</Form.Label>
              <Form.Control
                disabled
                value={pounds(
                  siteCalculations.financialModels[ownershipKey]?.license
                    ?.savings || 0
                )}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="leaseReveueY1">
            <Form.Label>Lease revenue (Y1)</Form.Label>
            <Form.Control
              disabled
              value={pounds(
                siteCalculations.financialModels[ownershipKey]?.lease
                  ?.revenue || 0
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="leaseRevenueLT">
            <Form.Label>Lease revenue (LT)</Form.Label>
            <Form.Control
              disabled
              value={pounds(
                siteCalculations.financialModels[ownershipKey]?.lease
                  ?.lifetimeRevenue || 0
              )}
            />
          </Form.Group>
        </Col>
        {isOwnerOccupied && (
          <Col>
            <Form.Group className="mb-3" controlId="leaseOOSavingsY1">
              <Form.Label>Lease OO savings (Y1)</Form.Label>
              <Form.Control
                disabled
                value={pounds(
                  siteCalculations.financialModels[ownershipKey]?.lease
                    ?.savings || 0
                )}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default FinancialReturns;
