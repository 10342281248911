import { useState } from "react";

import { Card, Col, Form, Row } from "react-bootstrap";

const twoDecimalPlaceString = (cost) => {
  if (typeof cost === "string") {
    // String values have been entered locally, so don't format these
    return cost;
  }
  return parseFloat(cost).toFixed(2);
};

const Cost = ({ site, setSite, errors, initialCost }) => {
  const onBlurHandler = (e) => {
    const { value } = e.target;
    e.target.value = isNaN(value) ? value : twoDecimalPlaceString(value);
  };

  const onChangeHandler = (e) => {
    const { value, id } = e.target;
    setSite({
      costInputs: {
        ...site.costInputs,
        [id]: value,
      },
    });
  };

  return (
    <CostView
      site={site}
      errors={errors}
      initialCost={initialCost}
      onBlurHandler={onBlurHandler}
      onChangeHandler={onChangeHandler}
      setSite={setSite}
    />
  );
};

const CostView = ({
  site,
  errors,
  initialCost,
  onBlurHandler,
  onChangeHandler,
  setSite,
}) => {
  const [displayCostInflationRates, setDisplayCostInflationRates] =
    useState(false);

  const range = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const costInflationRatesView = (
    <>
      <Row>
        {range(2, 25).map((year) => {
          return (
            <Col sm={3} key={year}>
              <Form.Group
                className="mb-3"
                controlId={`costInflationRates${year - 1}`}
              >
                <Form.Label>Year {year} inflation (%)</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={site.costInputs.costInflationRates[year - 1]}
                  onChange={(e) => {
                    const newCostInflationRates =
                      site.costInputs.costInflationRates;
                    newCostInflationRates[year - 1] = e.target.value;
                    setSite({
                      costInputs: {
                        ...site.costInputs,
                        costInflationRates: newCostInflationRates,
                      },
                    });
                  }}
                  isInvalid={
                    errors[`costInputs.costInflationRates.${year - 1}`]
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors[`costInputs.costInflationRates.${year - 1}`]}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        })}
      </Row>
    </>
  );

  const showCostInflationRatesLink = (
    <Col style={{ paddingTop: "40px" }}>
      <a
        onClick={() => setDisplayCostInflationRates(true)}
        style={{
          cursor: "pointer",
          color: "rgb(13, 110, 253)",
        }}
      >
        Show cost inflation rates
      </a>
    </Col>
  );

  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Costs and rates</strong>
      </Form.Label>
      <Row>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="panelCostPerKWp">
            <Form.Label>Panel cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(site.costInputs.panelCostPerKWp)}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputs.panelCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputs.panelCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="mountingCostPerKWp">
            <Form.Label>Mount. cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(site.costInputs.mountingCostPerKWp)}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputs.mountingCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputs.mountingCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="inverterCostPerKWp">
            <Form.Label>Inverter cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(site.costInputs.inverterCostPerKWp)}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputs.inverterCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputs.inverterCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="electricalCostPerKWp">
            <Form.Label>Elec. cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputs.electricalCostPerKWp
              )}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputs.electricalCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputs.electricalCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="bosCostPerKWp">
            <Form.Label>BOS cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(site.costInputs.bosCostPerKWp)}
              onBlur={(e) => onBlurHandler(e)}
              onChange={(e) => onChangeHandler(e)}
              isInvalid={errors["costInputs.bosCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputs.bosCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="miscCostPerKWp">
            <Form.Label>Misc cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(site.costInputs.miscCostPerKWp)}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputs.miscCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputs.miscCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="installationCostPerKWp">
            <Form.Label>Install cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputs.installationCostPerKWp
              )}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputs.installationCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputs.installationCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="initialCostPerKWp">
            <Form.Label>Upfront cost per kWp</Form.Label>
            <Form.Control
              disabled
              type="text"
              value={twoDecimalPlaceString(initialCost)}
              onBlur={(e) => onBlurHandler(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="maintenanceCostPerKWp">
            <Form.Label>O&M cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputs.maintenanceCostPerKWp
              )}
              onChange={(e) => onChangeHandler(e)}
              isInvalid={errors["costInputs.maintenanceCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputs.maintenanceCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="insuranceCostPerKWp">
            <Form.Label>Insurance cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(site.costInputs.insuranceCostPerKWp)}
              onChange={(e) => onChangeHandler(e)}
              isInvalid={errors["costInputs.insuranceCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputs.insuranceCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="gridConnectionCost">
            <Form.Label>Grid connection cost (£)</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(site.costInputs.gridConnectionCost)}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputs.gridConnectionCost"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputs.gridConnectionCost"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="replacementCapexYear">
            <Form.Label>PV replacement (years)</Form.Label>
            <Form.Control
              type="text"
              value={site.costInputs.replacementCapexYear}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputs.replacementCapexYear"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputs.replacementCapexYear"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="replacementCapexRate">
            <Form.Label>PV replacement rate (%)</Form.Label>
            <Form.Control
              type="text"
              value={site.costInputs.replacementCapexRate}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputs.replacementCapexRate"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputs.replacementCapexRate"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="energyPriceInflationRate">
            <Form.Label>Energy inflation (%)</Form.Label>
            <Form.Control
              type="text"
              value={site.energyPriceInflationRate}
              onChange={(e) =>
                setSite({ energyPriceInflationRate: e.target.value })
              }
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors.energyPriceInflationRate}
            />
            <Form.Control.Feedback type="invalid">
              {errors.energyPriceInflationRate}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {!displayCostInflationRates && showCostInflationRatesLink}
      </Row>
      {displayCostInflationRates && costInflationRatesView}
    </Card>
  );
};

export default Cost;
