import { ExtraErrorData, HttpClient } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";

import {
  KINDE_ENVIRONMENT,
  KindeAuthenticatedUI,
  KindeRedirectProvider,
} from "@inrange/auth";

import { GlobalStyle, ThemeProvider } from "@inrange/theme-components/styles";
import { QueryClientProvider } from "react-query";
import AppRoutes from "./AppRoutes";
import { UserProvider } from "./auth/UserContext";
import queryClient from "./queryClient";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://f9bb24586c704c40871064c57bf5c477@o1336164.ingest.sentry.io/4505561257738240",
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
      new HttpClient(),
      new ExtraErrorData(),
    ],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_BRANCH_NAME,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <KindeRedirectProvider>
    <KindeAuthenticatedUI
      kindeClientId={KINDE_ENVIRONMENT.KINDE_ADMIN_APP_CLIENT_ID}
      isDangerouslyUseLocalStorage={
        !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE
      }
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <GlobalStyle />
          <UserProvider>
            <AppRoutes />
          </UserProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </KindeAuthenticatedUI>
  </KindeRedirectProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
