import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { pounds } from "../siteSections/utils";

const Capex = ({ siteCalculations }) => {
  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Capex</strong>
      </Form.Label>
      <Card.Text className="text-secondary">
        The values marked with an asterisk (*) contribute to the hardware cost.
      </Card.Text>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Total project cost</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.total)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Upfront</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.initialInvestment)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Hardware</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.hardware)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Installation</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.installation)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Replacement</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.replacementCapex)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Panels *</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.panel)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Mounting *</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.mounting)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Inverters *</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.inverter)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Electrical *</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.electrical)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>BOS *</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.bos)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Misc *</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.misc)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>O&M (Y1)</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.maintenanceYearOne)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>O&M (Lifetime)</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.maintenance)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Insurance (Y1)</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.insuranceYearOne)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Insurance (LT)</Form.Label>
            <Form.Control
              disabled
              value={pounds(siteCalculations.projectCosts?.insurance)}
            />
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
};

export default Capex;
