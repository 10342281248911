import markerSVG from "@inrange/admin-app/src/routes/home.svg";
import { getMapboxUrl, mapboxKey } from "@inrange/building-manager-api-client";
import center from "@turf/center";
import { Icon, latLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import hash from "object-hash";
import { useNavigate } from "react-router-dom";

import { useRef, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import {
  GeoJSON,
  MapContainer,
  Marker,
  Pane,
  Popup,
  TileLayer,
  useMapEvent,
} from "react-leaflet";
import { Link } from "react-router-dom";
import MapAddressBar from "./AddressBar";
import { MapStyles } from "./MapStyles";

const mapbox_url = (theme) => getMapboxUrl(theme);

const mapboxAddressSearch = (lon, lat) => {
  return `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${mapboxKey}&autocomplete=true`;
};

const buildingPopupStyle = {
  width: "300px",
  color: "gray",
  fontSize: 10,
  display: "flex",
  flexDirection: "column",
  border: "1px solid #black",
};

const defaultCenter = [51.50032365386702, -0.12426640270284971];
const BuildingMap = ({
  sites,
  selectedSite,
  siteMarkers,
  isSiteList,
  mapStyle,
  setMapStyle,
  setMap,
  siteBuildings,
  buildingsInBounds,
  networkBuildingsInBounds,
  removeBuildingFromSite,
  renderedDeed,
  addBuildingToSite,
  siteAddress,
  addressCallback,
  setMapBounds,
  mapHeight,
  zoomToSite,
  // siteMapZoom, - currently unused
}) => {
  const [tileLoadingLabel, setTileLoadingLabel] = useState("tile-loading");
  const [mapSizeMeters, setMapSizeMeters] = useState(0);
  const MapBoundsChangedAction = () => {
    useMapEvent("moveend", (ee) => {
      const bounds = ee.target.getBounds();
      setMapBounds(bounds);
      setMapSizeMeters(bounds.getNorthEast().distanceTo(bounds.getSouthWest()));
    });
  };

  const params = new URLSearchParams(window.location.search);
  const testOrgId = params.get("testOrgId");

  const mapCenter = selectedSite
    ? [selectedSite.latitude, selectedSite.longitude]
    : sites?.length > 0
      ? [sites[0].latitude, sites[0].longitude]
      : defaultCenter;

  const myIcon = new Icon({
    iconUrl: markerSVG,
    iconSize: [16, 16],
  });

  const popupStyle1 = {
    marginTop: 10,
    color: "gray",
    fontSize: 10,
  };

  const bounds = latLngBounds([mapCenter]);

  const siteMarkerElements = (siteMarkers ? siteMarkers : sites)?.map(
    (site, ii) => {
      if (testOrgId && site.siteOwnerships.some((o) => o.orgID !== testOrgId)) {
        return;
      }
      if (isSiteList) {
        bounds.extend([site.latitude, site.longitude]);
      }
      return (
        <Marker
          position={[site.latitude, site.longitude]}
          key={ii}
          icon={myIcon}
          eventHandlers={{
            dblclick: () => zoomToSite(site),
          }}
        >
          <Popup>
            <div style={popupStyle1}>Name</div>
            <div>
              <a
                href={`/site/${site.id}/edit`}
                target="_blank"
                rel="noreferrer"
              >
                {site.name}
              </a>
            </div>
            <div style={popupStyle1}>Belongs To</div>
            <div>
              {site.siteOwnerships?.map((siteOrg) => (
                <div key={siteOrg.orgID}>
                  {siteOrg.name}: {siteOrg.ownership}
                </div>
              ))}
            </div>
            <div style={popupStyle1}>Adrress</div>
            <div>{site.address}</div>
          </Popup>
        </Marker>
      );
    }
  );

  const drawPolygons = mapSizeMeters < 7000;

  const mapZoom = 17;
  return (
    <div data-testid={tileLoadingLabel} id="site-building-map">
      <MapAddressBar
        addressCallback={addressCallback}
        initialValue={siteAddress}
      />
      <MapStyleSelector mapStyle={mapStyle} setMapStyle={setMapStyle} />
      <MapContainer
        style={{ width: "100%", height: mapHeight, margin: "0 0 10px" }}
        zoom={isSiteList ? undefined : mapZoom}
        scrollWheelZoom={false}
        ref={setMap}
        bounds={bounds}
        center={mapCenter}
        whenReady={() => {
          setMapSizeMeters(
            bounds.getNorthEast().distanceTo(bounds.getSouthWest())
          );
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={mapbox_url(mapStyle)}
          eventHandlers={{
            loading: () => setTileLoadingLabel("tile-loading"),
            load: () => setTileLoadingLabel("tile-loaded"),
          }}
        />
        <Pane name="site-buildings" style={{ zIndex: 3000 }}>
          <GeoBuildings
            buildings={drawPolygons ? siteBuildings : {}}
            style={solidGreenStyle}
            onClick={removeBuildingFromSite}
            type="site"
          />
        </Pane>
        <Pane name="network-buildings" style={{ zIndex: 2000 }}>
          <GeoBuildings
            buildings={drawPolygons ? networkBuildingsInBounds : {}}
            sitesData={sites}
            style={solidInRangeBlueStyle}
            popupMethod={addBuildingToSite}
            popup={true}
            type={isSiteList ? "networkList" : "network"}
          />
        </Pane>
        <Pane name="available-buildings" style={{ zIndex: 1000 }}>
          <GeoBuildings
            buildings={drawPolygons ? buildingsInBounds : {}}
            style={solidPurpleStyle}
            onClick={addBuildingToSite}
            popup={isSiteList}
            type="osm"
          />
        </Pane>
        <Pane name="popup" style={{ zIndex: 4000 }} />
        {drawPolygons ? null : siteMarkerElements}
        <GeoBuildings buildings={renderedDeed} style={solidBlueStyle} />;
        <MapBoundsChangedAction />
      </MapContainer>
    </div>
  );
};

export default BuildingMap;

const solidGreenStyle = {
  fillColor: "#207c1d",
  fillOpacity: 0.3,
  color: "#114210",
  weight: 2,
  opacity: 1,
};

const solidInRangeBlueStyle = {
  fillColor: "#2779a7",
  fillOpacity: 0.4,
  color: "#2779a7",
  weight: 2,
  opacity: 1,
};

const solidPurpleStyle = {
  fillColor: "#9D4EDD",
  fillOpacity: 0.3,
  color: "#3C096C",
  weight: 2,
  opacity: 1,
};

const solidBlueStyle = {
  fillOpacity: 0.1,
  color: "blue",
  weight: 2,
  opacity: 1,
};

const GeoBuildings = ({
  buildings,
  sitesData = [],
  style,
  onClick = () => {},
  popup,
  popupMethod = () => {},
  type,
}) => {
  if (!buildings) {
    return null;
  }

  const onPopupButtonClick = (key) => {
    popupMethod(key, buildings);
  };

  return Object.values(buildings).map((b) => {
    const key = b.key || hash(b.geometry);
    let buildingSiteData = [];
    if (sitesData.length > 0 && b.siteIDs) {
      buildingSiteData = b.siteIDs.map((siteID) =>
        sitesData.find((s) => s.id === siteID)
      );
    }

    return (
      <GeoJSON
        key={"geojson " + key}
        data={b.geometry}
        style={style}
        eventHandlers={{ click: () => onClick(key, buildings) }}
      >
        {popup ? (
          type.includes("network") ? (
            <NetworkBuildingPopup
              building={b}
              buildingSiteData={buildingSiteData}
              type={type}
              onPopupButtonClick={onPopupButtonClick}
            />
          ) : (
            <OsmBuildingPopup building={b} />
          )
        ) : null}
      </GeoJSON>
    );
  });
};

const OsmBuildingPopup = ({ building }) => {
  return (
    <Popup pane="popup">
      <OsmBuildingInfo building={building} />
    </Popup>
  );
};

const OsmBuildingInfo = ({ building }) => {
  const [address, setAddress] = useState(undefined);
  const lonLatCoord = center(building.geometry).geometry.coordinates;
  fetch(mapboxAddressSearch(lonLatCoord[0], lonLatCoord[1]))
    .then((res) => res.json())
    .then((data) => {
      setAddress(
        data?.features.find((feature) =>
          feature.place_type.includes("postcode")
        ).place_name || "No address found"
      );
    });

  const formatMeters = (meters) => {
    return new Intl.NumberFormat("en-GB", {}).format(meters.toFixed(0)) + " m²";
  };

  return (
    <div style={buildingPopupStyle}>
      <SiteInfoElement title="Location">
        <div data-testid={"building-popup-location"}>
          {address || "Loading..."}
        </div>
      </SiteInfoElement>
      <SiteInfoElement title="Building size">
        <div data-testid={"building-popup-area"}>
          {formatMeters(building.surface_area_sqm)}
        </div>
      </SiteInfoElement>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Link
          to={`/site/new?lat=${lonLatCoord[1]}&lon=${lonLatCoord[0]}&bkey=${building.key}&address=${address}`}
        >
          <Button variant="success">New site</Button>
        </Link>
      </div>
    </div>
  );
};

const NetworkBuildingPopup = ({
  building,
  buildingSiteData,
  onPopupButtonClick,
  type,
}) => {
  // we seperate the popup component from the building site info component
  // to prevent fetching the building site info before the popup is clicked
  const popupRef = useRef();
  return (
    <Popup ref={popupRef} pane="popup">
      <NetworkBuildingSiteInfo
        building={building}
        buildingSiteData={buildingSiteData}
        onPopupButtonClick={onPopupButtonClick}
        type={type}
        popupRef={popupRef}
      />
    </Popup>
  );
};

const NetworkBuildingSiteInfo = ({
  building,
  buildingSiteData,
  onPopupButtonClick,
  type,
  popupRef,
}) => {
  const isSiteEdit = type === "network";

  const navigate = useNavigate();

  const key = building.key || hash(building.geometry);

  const siteInfos = buildingSiteData.map((site) => (
    <SiteInfo site={site} key={site.id} />
  ));

  return (
    <div style={buildingPopupStyle} data-testid="inrange-building-popup">
      <div
        style={{
          maxHeight: "250px",
          overflowY: "auto",
          scrollbarWidth: "thin",
        }}
      >
        <hr />
        {siteInfos.length ? siteInfos : "Error fetching building's site data"}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {isSiteEdit ? (
          <Button
            variant="success"
            onClick={() => {
              onPopupButtonClick(key);
              // close the popup
              popupRef.current._closeButton.click();
            }}
          >
            Add building
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              const lonLatCoord = center(building.geometry).geometry
                .coordinates;
              fetch(mapboxAddressSearch(lonLatCoord[0], lonLatCoord[1]))
                .then((res) => res.json())
                .then((data) => {
                  const address =
                    data?.features.find((feature) =>
                      feature.place_type.includes("postcode")
                    ).place_name || "No address found";
                  navigate(
                    `/site/new?lat=${lonLatCoord[1]}&lon=${lonLatCoord[0]}&bkey=${building.key}&address=${address}`
                  );
                });
            }}
          >
            New site
          </Button>
        )}
      </div>
    </div>
  );
};

const SiteInfo = ({ site }) => {
  const siteID = site.id;

  return (
    <>
      <div>
        <SiteInfoElement title="Name">
          <Link
            to={`../site/${siteID}/edit`}
            data-testid={"building-popup-name"}
          >
            {site.name || "No name"}
          </Link>
        </SiteInfoElement>
        <SiteInfoElement
          title="Belongs to"
          dataTestId={"building-popup-ownership"}
        >
          {site.siteOwnerships.length
            ? Object.values(site.siteOwnerships).map((ownership) => (
                <div
                  key={ownership.orgID}
                  data-testid={"building-popup-ownership"}
                >
                  {ownership.name}:{" "}
                  {ownership.ownership === "ownerOccupier"
                    ? "owner occupier"
                    : ownership.ownership}
                </div>
              ))
            : "No owners"}
        </SiteInfoElement>
        <SiteInfoElement title="Address">
          <div data-testid={"building-popup-address"}>
            {site.address || "No address"}
          </div>
        </SiteInfoElement>
      </div>
      <hr />
    </>
  );
};

const SiteInfoElement = ({ title, children, marginBottom = "20px" }) => {
  return (
    <div style={{ marginBottom }}>
      <label>{title}</label>
      <div style={{ fontSize: 12, color: "#212529" }}>{children}</div>
    </div>
  );
};

const MapStyleSelector = ({ mapStyle, setMapStyle }) => {
  return (
    <ButtonGroup
      style={{
        position: "absolute",
        zIndex: 401,
        marginTop: 90,
        marginLeft: 10,
      }}
    >
      <Button
        size="sm"
        variant="secondary"
        active={mapStyle === MapStyles.streets}
        onClick={() => setMapStyle(MapStyles.streets)}
      >
        Streets
      </Button>
      <Button
        size="sm"
        variant="secondary"
        active={mapStyle === MapStyles.satellite}
        onClick={() => setMapStyle(MapStyles.satellite)}
      >
        Satellite
      </Button>
    </ButtonGroup>
  );
};
