import {
  useOrganisation,
  useReports,
  useSiteList,
} from "@inrange/building-manager-api-client";
import { sortBy } from "@inrange/calculations/utils";
import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { csvHeaders, formatCsvRow } from "../utils/SitesCsvUtil";
import Loading from "./Loading";

const Reports = () => {
  document.title = "Reports";
  const [autorefetch, setAutorefetch] = useState(false);
  const [lastSeenSuccessStartTimes, setLastSeenSuccessStartTimes] =
    useState("");
  const { fetchReports, invokeReports } = useReports(
    autorefetch ? 60_000 : false
  );
  const [loadSiteData, setLoadSiteData] = useState(false);

  const succeededStartTimes = (fetchReports.data?.executions || [])
    .filter((execution) => execution.status === "SUCCEEDED")
    .map((execution) => execution.startDate)
    .join(",");
  const runningCount = (fetchReports.data?.executions || []).filter(
    (execution) => execution.status === "RUNNING"
  ).length;
  useEffect(() => {
    if (
      runningCount == 0 &&
      succeededStartTimes &&
      succeededStartTimes !== lastSeenSuccessStartTimes &&
      autorefetch
    ) {
      setLastSeenSuccessStartTimes(succeededStartTimes);
      setAutorefetch(false);
    } else if (runningCount > 0 && !autorefetch) {
      setAutorefetch(true);
    }
  }, [
    succeededStartTimes,
    lastSeenSuccessStartTimes,
    runningCount,
    autorefetch,
  ]);

  if (fetchReports.isLoading) return <Loading label="Loading reports..." />;

  if (fetchReports.isError) {
    return <div>Error loading the reports.</div>;
  }

  return (
    <>
      <Container className="mt-4">
        <Button
          variant="success"
          onClick={() => {
            setAutorefetch(true);
            invokeReports.mutate();
          }}
        >
          Generate reports
        </Button>
        <h3>Report Outputs</h3>
        <ul>
          {Object.entries(fetchReports.data?.reports || {})
            .sort(sortBy((a) => a[0]))
            .map(([reportName, files], index) => (
              <li key={index}>
                {reportName}
                <ul>
                  {files.map((file, index) => (
                    <li key={index}>
                      <a
                        href={file.presignedUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {reportName}
                      </a>{" "}
                      - {file.lastModified.split("+")[0]}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
        </ul>
        <h3>Report Runs</h3>
        <ul>
          {(fetchReports.data?.executions || []).map((execution, index) => (
            <li key={index}>
              Status: {execution.status} - Started:{" "}
              {execution.startDate.split(".")[0]} - Stopped:{" "}
              {(execution.stopDate || "").split(".")[0]}
            </li>
          ))}
        </ul>
        <h3>Site Data</h3>
        {!loadSiteData && (
          <Button variant="success" onClick={() => setLoadSiteData(true)}>
            Load All Sites Data
          </Button>
        )}
        {loadSiteData && <SiteDataExport />}
      </Container>
    </>
  );
};

const SiteDataExport = () => {
  const { fetchSites } = useSiteList();
  const { useOrganisationSites } = useOrganisation();
  const sites = useOrganisationSites(
    (fetchSites.data?.sites || []).map((site) => {
      return { siteID: site.id };
    })
  );

  const anySitesLoading = sites.some((site) => site.isLoading);

  if (fetchSites.isLoading)
    return <Loading height="100px" label="Loading site list ..." />;

  if (anySitesLoading) {
    return <Loading height="100px" label={`Loading site data ...`} />;
  }

  const successfulSites = sites
    .filter((site) => site.isSuccess)
    .map((site) => site.data?.site)
    .sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

  const csvData = successfulSites.map((site) => {
    return formatCsvRow(site, "all-sites");
  });
  const dateString = new Date().toISOString().split(".")[0];

  return (
    <CSVLink
      data={csvData}
      headers={csvHeaders}
      filename={"Site List " + dateString + ".csv"}
    >
      <Button variant="primary">Download sites data</Button>
    </CSVLink>
  );
};

export default Reports;
