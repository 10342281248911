import { useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useSite } from "@inrange/building-manager-api-client";
import Loading from "../Loading";

import SdmAddMatchModal from "./siteSections/SdmAddMatchModal";
import SdmAppliedMatches from "./siteSections/SdmAppliedMatches";
import SdmDeleteMatchModal from "./siteSections/SdmDeleteMatchModal";
import SdmOffers from "./siteSections/SdmOffers";
import SiteNav from "./siteSections/SiteNav";

const SiteSdm = () => {
  const { siteId } = useParams();
  const { fetchSite, updateSite, siteAddMatch, siteDeleteMatch } = useSite({
    siteId,
  });
  const [selectedAddOffer, setSelectedAddOffer] = useState(undefined);
  const [selectedDeleteOffer, setSelectedDeleteOffer] = useState(undefined);

  if (fetchSite.isLoading) {
    return <Loading label="Loading site data..." />;
  }

  if (fetchSite.isError) {
    return <div>Error loading the site.</div>;
  }

  if (siteAddMatch.isError) {
    alert(`Error adding match - ${siteAddMatch.error.message}`);
    siteAddMatch.reset();
  }

  if (siteDeleteMatch.isError) {
    alert(`Error deleting match - ${siteDeleteMatch.error.message}`);
    siteDeleteMatch.reset();
  }

  const site = fetchSite.data?.site;

  document.title = `Site: ${site.name}`;

  return (
    <>
      <SiteNav />
      <Container fluid style={{ padding: "12px 12px 0px 12px" }}>
        <div style={{ padding: "0 0 12px 0", fontSize: "26px" }}>
          {site.name}
        </div>
        <SdmAppliedMatches
          site={site}
          setSelectedDeleteOffer={setSelectedDeleteOffer}
        />
        <SdmOffers
          site={site}
          updateSite={updateSite}
          setSelectedAddOffer={setSelectedAddOffer}
        />
        {selectedAddOffer !== undefined && (
          <SdmAddMatchModal
            site={site}
            selectedAddOffer={selectedAddOffer}
            setSelectedAddOffer={setSelectedAddOffer}
            siteAddMatch={siteAddMatch}
          />
        )}
        {selectedDeleteOffer !== undefined && (
          <SdmDeleteMatchModal
            site={site}
            selectedDeleteOffer={selectedDeleteOffer}
            setSelectedDeleteOffer={setSelectedDeleteOffer}
            siteDeleteMatch={siteDeleteMatch}
          />
        )}
      </Container>
    </>
  );
};

export default SiteSdm;
