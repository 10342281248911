import { Nav } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";

const SiteNav = () => {
  const { siteId } = useParams();
  const location = useLocation();
  return (
    <Nav
      activeKey={location.pathname}
      expand="md"
      style={{ padding: "8px 12px", backgroundColor: "#d9d9d9" }}
    >
      <Nav.Item>
        <Nav.Link
          eventKey={`/site/${siteId}/edit`}
          as={Link}
          to={`/site/${siteId}/edit`}
        >
          Site Data
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey={`/site/${siteId}/sdm`}
          as={Link}
          to={`/site/${siteId}/sdm`}
        >
          SDM
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default SiteNav;
